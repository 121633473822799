import React, { useState, useEffect,useRef, act } from "react";
import DeliveryIcon from "../Assets/DeliveryIcon.png";
import ReturnIcon from "../Assets/ReturnIcon.png";
import Star from "../Assets/Star.png";
import StarFill from "../Assets/StarFill.png";
import { useLocation } from "react-router-dom";
import {auth} from '../Components/FirebaseInit.js';
import { onAuthStateChanged } from "firebase/auth";
import { shippingPolicy, returnPolicy } from "../TemporaryData/TemporaryData";
import Pic1 from "../Assets/Pic1.jpg";
import Pic2 from "../Assets/Pic2.jpg";
import Pic3 from "../Assets/Pic3.jpg";
import Pic4 from "../Assets/Pic4.jpg";
import Pic5 from "../Assets/Pic5.jpg";
import Pic6 from "../Assets/Pic6.jpg";
import Pic7 from "../Assets/Pic7.jpg";
import LeftArrowIcon from "../Assets/LeftArrowIcon.png";
import RightArrowIcon from "../Assets/RightArrowIcon.png";
import CloseIcon from "../Assets/CloseIcon.png";
import ReviewCard from "../Cards/ReviewCard";
import Cart from "../Components/Cart.js";
import Header from "../Components/Header";
import WhatsappIcon from "../Assets/WhatappIcon.png";
import { useNavigate } from "react-router-dom";
function AboutProduct() {
  const navigate=useNavigate();
  const testRef = useRef(null);
  const posRef = useRef(0);
  const [position, setPosition] = useState(0);
  const[isLoggedIn,setIsLoggedIn]=useState(false);
  useEffect(()=>
    {
      onAuthStateChanged(auth, (user) => {
        if (user) {
       
        setIsLoggedIn(true);
        
        } else {
        setIsLoggedIn(false);
  
        }
      });
    },[]);
  const addToCartClicked=async(name,cost,pictureUrl,action)=>
  {
    let cart=await localStorage.getItem("cart");
    console.log("Cart:"+cart);
    let tempCart=[];
    if(cart===null)
    {
      
      if(action=="Add")
      {
      tempCart.push({name:name,quantity:1,cost:cost,pictureUrl:pictureUrl});
      localStorage.setItem("cart",JSON.stringify(tempCart));
      setShowCart(true);
      }

    }
    else if(cart!==null)
    {
      if(cart.length==0)
      {
        tempCart.push({name:name,quantity:1,cost:cost,pictureUrl:pictureUrl});
        localStorage.setItem("cart",JSON.stringify(tempCart));
        setShowCart(true);
      }
      else
      {
      tempCart=[...JSON.parse(localStorage.getItem("cart"))];
      let tempProductIndex=tempCart.findIndex(e=>e.name==name);
      if(tempProductIndex!=-1)
{
      if(action=="Add")
      {
      tempCart[tempProductIndex].quantity+=1;
      localStorage.setItem("cart",JSON.stringify(tempCart));
      setShowCart(true);
      }
      else if(action=="Remove")
      {
        if(tempCart[tempProductIndex].quantity==1)
        {
          tempCart.splice(tempProductIndex,1);
          if(tempCart.length==0)
          {
            localStorage.setItem("cart","");
          }
          else
          {
            setShowCart(true);
          }
        }
        else
        {
          tempCart[tempProductIndex].quantity-=1;
          localStorage.setItem("cart",JSON.stringify(tempCart));
          setShowCart(true);
        }
      }
    }
    else
    {
      tempCart.push({name:name,quantity:1,cost:cost,pictureUrl:pictureUrl});
      localStorage.setItem("cart",JSON.stringify(tempCart));
      setShowCart(true);
    }
  }
    }
   

  }
  const onPressLeftRightSmall = (purpose) => {
    let tempPosition = position;

    console.log(posRef.current);
    setPosition(tempPosition);
    if (purpose == "Right") {
      tempPosition += 1;
      if (tempPosition > location.state.pictures.split('|').length - 1) {
        tempPosition = 0;
      }
      posRef.current += 1;
      if (posRef.current > location.state.pictures.split('|').length - 1) {
        posRef.current = 0;
      }
    } else if (purpose == "Left") {
      tempPosition -= 1;
      if (tempPosition < 0) {
        tempPosition =location.state.pictures.split('|').length - 1;
      }
      posRef.current -= 1;
      if (posRef.current < 0) {
        posRef.current = location.state.pictures.split('|').length - 1;
      }
    }
    testRef.current.scrollTo({
      left: window.innerWidth * posRef.current,
      top: 0,
      behaviour: "smooth",
    });
  };
  const [selectedOption, setSelectedOption] = useState("Overview");
  const [selectedColor, setSelectedColor] = useState("Red");
  const [selectedSize, setSelectedSize] = useState("");
  const [showFullPicture, setShowFullPicture] = useState(false);
  let picturesArray = [Pic1, Pic2, Pic3, Pic4, Pic5, Pic6, Pic7];
  const [showCart, setShowCart] = useState(false);
  const [selectedPictureIndex, setSelectedPictureIndex] = useState(0);
  const onPressLeftRightButton = (purpose) => {
    let index = selectedPictureIndex;
    if (purpose == "Left") {
      if (index == 0) {
        index = location.state.pictures.split('|').length - 1;
      } else {
        index -= 1;
      }
    } else if (purpose == "Right") {
      if (index == location.state.pictures.split('|').length - 1) {
        index = 0;
      } else {
        index += 1;
      }
    }
    setSelectedPictureIndex(index);
  };
  const reviews = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];

  const getTheReviewTime = (_date) => {
    let msecs = new Date().getTime() - new Date(_date).getTime();
    let secs = msecs / 1000;
    let mins = secs / 60;
    let hours = mins / 60;
    let days = hours / 24;
    let weeks = days / 7;
    let months = weeks / 4;
    let years = months / 12;
    if (secs > 59) {
      if (mins > 59) {
        if (hours > 23) {
          if (days > 6) {
            if (weeks > 3) {
              if (months > 11) {
                return years == 0 ? 1 + "y" : years.toFixed(0) + "y";
              } else {
                return months == 0 ? 1 + "mo" : months.toFixed(0) + "mo";
              }
            } else {
              return weeks == 0 ? 1 + "w" : weeks.toFixed(0) + "w";
            }
          } else {
            return days == 0 ? 1 + "d" : days.toFixed(0) + "d";
          }
        } else {
          return hours == 0 ? 1 + "h" : hours.toFixed(0) + "h";
        }
      } else {
        return mins == 0 ? 1 + "m" : mins.toFixed(0) + "m";
      }
    } else {
      return secs == 0 ? 1 + "s" : secs.toFixed(0) + "s";
    }
  };
  // ${
  //   // showFullPicture == true ? "h-screen" : "h-auto"

  // }
  // h-[49%]
  // h-[49%] overflow-y-auto
  const location=useLocation();
  return (
    <div className={`w-screen  flex flex-col relative  h-screen`}>
      <Header props={{ onPressCart: () => setShowCart(true) }} />
      {showFullPicture == false ? (
        <div className="flex flex-col w-[98%] self-center h-[90vh] overflow-y-auto  ">
          <div className="flex flex-col  gap-y-[20px] ">
            <div className="font-bold text-center">
              {location.state.name}
            </div>
            <div className="flex flex-row justify-center items-center  relative">
              <div
                className="flex flex-row overflow-x-auto w-full gap-x-[10px] items-center justify-center"
                ref={testRef}
              >
                <img
                  src={location.state.pictures.split('|')[0]}
                  alt="pic1"
                  className="2xl:w-[30%] xl:w-[30%] lg:w-[30%] w-full h-[300px] object-fit flex-none cursor-pointer"
                  onClick={() => {
                    setSelectedPictureIndex(0);
                    setShowFullPicture(true);
                  }}
                />
                <div className="text-black text-base self-center absolute bottom-[15px] z-[400] w-full text-center flex flex-col justify-center items-center  p-[2px] font-bold">
                  <div className="bg-gray-100 p-[2px] opacity-45 w-[10%] h-full absolute -z-[100] rounded-full" />
                  <div>
                    {selectedPictureIndex + 1}/{location.state.pictures.split('|').length}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row overflow-x-auto w-full gap-x-[5px]">
              <div
                className={`px-[5px] py-[2px] ${
                  selectedOption == "Overview" ? "bg-[#D3D3D3]" : "bg-[#FFFFFF]"
                }  ${
                  selectedOption == "Overview"
                    ? "text-black border-b-0 rounded-t-md"
                    : "text-black rounded-lg border-b-2 "
                }  font-semibold  cursor-pointer border-t-2  border-l-2 border-r-2 
            ${
              selectedOption == "Overview"
                ? "border-[#D3D3D3]"
                : "border-[#F5F5F5]"
            } h-[40px] text-base justify-center items-center flex flex-col`}
                onClick={() => setSelectedOption("Overview")}
              >
                Overview
              </div>
              <div
                className={`px-[5px] py-[2px] ${
                  selectedOption == "Reviews" ? "bg-[#D3D3D3]" : "bg-[#F5F5F5]"
                }  ${
                  selectedOption == "Reviews"
                    ? "text-black border-b-0 rounded-t-md"
                    : "text-black rounded-lg border-b-2 "
                }  font-semibold  cursor-pointer border-t-2  border-l-2 border-r-2
              ${
                selectedOption == "Reviews"
                  ? "border-[#D3D3D3]"
                  : "border-[#FFFFFF]"
              } h-[40px] text-base justify-center items-center flex flex-col`}
                onClick={() => setSelectedOption("Reviews")}
              >
                Reviews
              </div>
              <div
                className={`px-[5px] py-[2px] ${
                  selectedOption == "Delivery/Return"
                    ? "bg-[#D3D3D3]"
                    : "bg-[#FFFFFF]"
                }  ${
                  selectedOption == "Delivery/Return"
                    ? "text-black border-b-0 rounded-t-md"
                    : "text-black rounded-lg border-b-2 "
                }  font-semibold  cursor-pointer border-t-2  border-l-2 border-r-2 
              ${
                selectedOption == "Delivery/Return"
                  ? "border-[#D3D3D3]"
                  : "border-[#F5F5F5]"
              } h-[40px] text-base justify-center items-center flex flex-col`}
                onClick={() => setSelectedOption("Delivery/Return")}
              >
                Delivery/Return
              </div>
            </div>
          </div>
          <div
            className={`flex flex-col w-full bg-[#D3D3D3] p-[5px] h-[35vh] overflow-y-auto`}
          >
            {/*********Body**********/}
            {selectedOption == "Overview" ? (
              <div className="flex flex-col gap-y-[5px]">
                <div className="flex flex-row w-full justify-between items-center">
                  <div className="text-gray-900 text-base">
                    <span className="font-bold text-black text-md">
                      {" "}
                      HKD.<span className="line-through">{location.state.cost}</span>{" "}
                    </span>
                    <span className="font-bold text-black text-md ">{location.state.cost-location.state.cost*(location.state.discount/100)} </span>
                    Incl. all taxes
                  </div>
                  <div className="flex flex-row">
                    <img src={Star} className="w-[20px] h-[20px] object-fit" />
                    <img src={Star} className="w-[20px] h-[20px] object-fit" />
                    <img src={Star} className="w-[20px] h-[20px] object-fit" />
                    <img src={Star} className="w-[20px] h-[20px] object-fit" />
                    <img src={Star} className="w-[20px] h-[20px] object-fit" />
                  </div>
                </div>
                {/**********Color Selection Section Starts********/}
                <div className="font-medium">
                  <span className="font-bold">Color:</span> {selectedColor}{" "}
                </div>
                <div className="flex flex-row w-full gap-x-[5px]">
                  <div
                    onClick={() => setSelectedColor("Red")}
                    className={`w-[20px] h-[20px] border-2 ${
                      selectedColor == "Red"
                        ? "border-black"
                        : "border-transparent"
                    } rounded-full bg-red-500 cursor-pointer`}
                  />
                  <div
                    onClick={() => setSelectedColor("Yellow")}
                    className={`w-[20px] h-[20px] border-2 ${
                      selectedColor == "Yellow"
                        ? "border-black"
                        : "border-transparent"
                    }  rounded-full bg-yellow-500 cursor-pointer`}
                  />
                  <div
                    onClick={() => setSelectedColor("Blue")}
                    className={`w-[20px] h-[20px] border-2 ${
                      selectedColor == "Blue"
                        ? "border-black"
                        : "border-transparent"
                    }  rounded-full bg-blue-500 cursor-pointer`}
                  />
                  <div
                    onClick={() => setSelectedColor("Orange")}
                    className={`w-[20px] h-[20px] border-2 ${
                      selectedColor == "Orange"
                        ? "border-black"
                        : "border-transparent"
                    }  rounded-full bg-orange-500 cursor-pointer`}
                  />
                </div>
                {/**********Color Selection Section Ends********/}
                {/**********Size Selection Section Starts********/}
                <div className="font-medium">
                  <span className="font-bold">Size</span>
                </div>
                <div className="grid 2xl-grid-cols-6 xl-grid-cols-6 lg-grid-cols-5 grid-cols-4 gap-y-[10px] gap-x-[2.5%]">
                  {location.state.availableSizes.split('|').map((item,index)=>
                  <div
                    key={index}
                    onClick={() => setSelectedSize(item)}
                    className={`w-[95%] py-[2px] border-2 
                ${selectedSize == item ? "border-black" : "border-transparent"}
                ${selectedSize == item ? "bg-black" : "bg-gray-200"}
                ${selectedSize == item ? "text-[#F5F5F5]" : "text-black"} 
                 rounded-md text-center cursor-pointer text-base`}
                  >
                    {item}
                  </div>)}
                  {/* <div
                    onClick={() => setSelectedSize("3-6")}
                    className={`w-[95%] py-[2px] border-2 
                ${selectedSize == "3-6" ? "border-black" : "border-transparent"}
                ${selectedSize == "3-6" ? "bg-black" : "bg-gray-200"}
                ${selectedSize == "3-6" ? "text-[#F5F5F5]" : "text-black"} 
                 rounded-md text-center cursor-pointer text-base`}
                  >
                    3-6 M
                  </div>
                  <div
                    onClick={() => setSelectedSize("6-9")}
                    className={`w-[95%] py-[2px] border-2 
                ${selectedSize == "6-9" ? "border-black" : "border-transparent"}
                ${selectedSize == "6-9" ? "bg-black" : "bg-gray-200"}
                ${selectedSize == "6-9" ? "text-[#F5F5F5]" : "text-black"} 
                 rounded-md text-center cursor-pointer text-base`}
                  >
                    6-9 M
                  </div>
                  <div
                    onClick={() => setSelectedSize("9-12")}
                    className={`w-[95%] py-[2px] border-2 
                ${
                  selectedSize == "9-12" ? "border-black" : "border-transparent"
                }
                ${selectedSize == "9-12" ? "bg-black" : "bg-gray-200"}
                ${selectedSize == "9-12" ? "text-[#F5F5F5]" : "text-black"} 
                 rounded-md text-center cursor-pointer text-base`}
                  >
                    9-12 M
                  </div>
                  <div
                    onClick={() => setSelectedSize("12-15")}
                    className={`w-[95%] py-[2px] border-2 
                ${
                  selectedSize == "12-15"
                    ? "border-black"
                    : "border-transparent"
                }
                ${selectedSize == "12-15" ? "bg-black" : "bg-gray-200"}
                ${selectedSize == "12-15" ? "text-[#F5F5F5]" : "text-black"} 
                 rounded-md text-center cursor-pointer text-base`}
                  >
                    12-15 M
                  </div>
                  <div
                    onClick={() => setSelectedSize("15-18")}
                    className={`w-[95%] py-[2px] border-2 
                ${
                  selectedSize == "15-18"
                    ? "border-black"
                    : "border-transparent"
                }
                ${selectedSize == "15-18" ? "bg-black" : "bg-gray-200"}
                ${selectedSize == "15-18" ? "text-[#F5F5F5]" : "text-black"} 
                 rounded-md text-center cursor-pointer text-base`}
                  >
                    15-18 M
                  </div> */}
                </div>
                {/**********Size Selection Section Ends********/}
                
                {localStorage.getItem('cart')!=null?
                localStorage.getItem('cart').length>0?
                JSON.parse(localStorage.getItem('cart')).findIndex(e=>e.name==location.state.name)==-1?
                <button
                  className="bg-[#DC143C] text-white rounded-md py-[10px] font-bold mt-[10px]"
                  onClick={() =>{isLoggedIn==false?navigate("/login"):addToCartClicked(location.state.name,location.state.cost,location.state.pictures.split('|')[0],"Add")}}
                >
                  ADD TO BAG
                </button>:
                <div className="flex flex-row gap-x-[10px] items-center justify-center border-[0.25px] border-[#FFFFFF] rounded-md p-[5px]">
                  <button className="w-[20%] rounded-sm bg-white text-black font-bold" onClick={()=>{isLoggedIn==false?navigate("/login"):addToCartClicked(location.state.name,null,null,"Remove")}}>-</button>
                  <h1>{JSON.parse(localStorage.getItem('cart'))[JSON.parse(localStorage.getItem('cart')).findIndex(e=>e.name==location.state.name)].quantity}</h1>
                  <button className="w-[20%] rounded-sm bg-white text-black font-bold" onClick={()=>{isLoggedIn==false?navigate("/login"):addToCartClicked(location.state.name,null,null,"Add")}}>+</button>
                </div>
                :<button
                className="bg-[#DC143C] text-white rounded-md py-[10px] font-bold mt-[10px]"
                onClick={() => {isLoggedIn==false?navigate("/login"):addToCartClicked(location.state.name,location.state.cost,location.state.pictures.split('|')[0],"Add")}}
              >
                ADD TO BAG
              </button>
                :<button
                className="bg-[#DC143C] text-white rounded-md py-[10px] font-bold mt-[10px]"
                onClick={() =>{isLoggedIn==false?navigate("/login"):addToCartClicked(location.state.name,location.state.cost,location.state.pictures.split('|')[0],"Add")}}
              >
                ADD TO BAG
              </button>}
              </div>
            ) : null}
            {/**********Overview Selection Section Ends********/}
            {/**********Review Section Starts********/}
            {selectedOption == "Reviews" ? (
              <div className="flex flex-col gap-y-[10px]">
                {reviews.map((item, index) => (
                  <ReviewCard
                    key={index}
                    props={{
                      customerName: "Olivia",
                      customerReview:
                        "The SpongeBob design on these PJs is spot-on! My kids love the characters, and they’re comfortable enough to wear all night. They’ve held up well in the wash too. ",
                      reviewTime: getTheReviewTime("2024-09-12 00:00:00"),
                    }}
                  />
                ))}
                <button className="bg-black text-white rounded-md p-[5px]">
                  Load More Reviews
                </button>
              </div>
            ) : null}
            {selectedOption == "Delivery/Return" ? (
              <div className="flex flex-col gap-y-[10px]">
                <div className="flex flex-col">
                  <div className="flex flex-row items-center gap-x-[5px] ">
                    <img
                      className="w-[25px] h-[25px] object-fit"
                      src={DeliveryIcon}
                    />
                    <div className="font-bold">{shippingPolicy.title}</div>
                  </div>
                  <div className="font-medium">
                    {shippingPolicy.description}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex flex-row items-center gap-x-[5px] ">
                    <img
                      className="w-[25px] h-[25px] object-fit"
                      src={ReturnIcon}
                    />
                    <div className="font-bold">{returnPolicy.title}</div>
                  </div>
                  <div className="font-medium">{returnPolicy.description}</div>
                </div>
              </div>
            ) : null}
            {/*********Body**********/}
          </div>
        </div>
      ) : null}

      {showFullPicture == true ? (
        <div className="flex flex-col w-full h-full self-center top-[15px] z-[500] bg-[#D3D3D3]">
          <div className="w-[95%] h-full flex flex-row relative justify-center items-center gap-x-[2px]">
            <div className="text-black text-base self-center absolute top-[15px] font-bold">
              {selectedPictureIndex + 1}/{location.state.pictures.split('|').length}
            </div>
            <img
              src={CloseIcon}
              className="w-[20px] h-[20px] object-contain absolute top-[15px] right-[5px]"
              onClick={() => {
                setSelectedPictureIndex(0);
                setShowFullPicture(false);
              }}
            />
            <img
              src={LeftArrowIcon}
              className="w-[25px] h-[25px] object-contain cursor-pointer"
              onClick={() => onPressLeftRightButton("Left")}
            />
            <img
              src={location.state.pictures.split('|')[selectedPictureIndex]}
              className="w-[300px] h-[500px] object-contain "
            />
            <img
              src={RightArrowIcon}
              className="w-[25px] h-[25px] object-contain cursor-pointer"
              onClick={() => onPressLeftRightButton("Right")}
            />
          </div>
        </div>
      ) : null}
      {showCart == true ? (
        <Cart props={{ closeButtonAction: () => setShowCart(false) }} />
      ) : null}
      <img
        src={WhatsappIcon}
        className="w-[50px] h-[50px] object-contain absolute bottom-[5px] right-[5px] cursor-pointer"
        onClick={()=>window.open(`https://wa.me/85251136004`)}
      />
    </div>
  );
}
export default AboutProduct;
