import React from "react";
import Star from "../Assets/Star.png";
import StarFill from "../Assets/StarFill.png";
import AddToBagIcon from "../Assets/AddToBagIcon.png";
function RecommendedCard({ props }) {
  return (
    <div className="flex flex-col flex-none justify-between w-[150px]">
      <img
        src={props.productImage}
        className="w-[150px] h-[150px] object-fill"
      />
      <div className="text-black px-[5px] font-semibold text-[12px] w-[150px]">
        {props.productName}
      </div>
    </div>
  );
}
export default RecommendedCard;
