import React, { useState,useEffect} from "react";
import CloseIcon from "../Assets/CloseIcon.png";
import DeliveryIcon from "../Assets/DeliveryIcon.png";
import ProductCard from "../Cards/ProductCard";
import CartCard from "../Cards/CartCard";
import { ProductData } from "../TemporaryData/TemporaryData";
import PaymentElementStripte from './PaymentElementStripe.js';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

function Cart({ props }) {
  const[cartArray,setCartArray]=useState([]);
  useEffect(()=>
  {
    if(localStorage.getItem("cart").length>0)
    {
      let tempCart=[...JSON.parse(localStorage.getItem('cart'))];
      console.log("Length of cart:"+tempCart.length);
      setCartArray(tempCart);
    }
    else
    {
      setCartArray([]);
    }
  },[]);
  const addToCartClicked=async(name,cost,pictureUrl,action)=>
    {

      let cart=await localStorage.getItem("cart");
      console.log("Cart:"+cart);
      let tempCart=[];
      if(cart===null)
      {
        if(action=="Add")
        {
        tempCart.push({name:name,quantity:1,cost:cost,pictureUrl:pictureUrl});
        setCartArray(tempCart);
        localStorage.setItem("cart",JSON.stringify(tempCart));
        }
  
      }
      else if(cart!==null)
      {
        if(cart.length==0)
        {
          tempCart.push({name:name,quantity:1,cost:cost,pictureUrl:pictureUrl});
          setCartArray(tempCart);
          localStorage.setItem("cart",JSON.stringify(tempCart));
        }
        else
        {
        tempCart=[...JSON.parse(localStorage.getItem("cart"))];
        let tempProductIndex=tempCart.findIndex(e=>e.name==name);
        if(tempProductIndex!=-1)
        {
        if(action=="Add")
        {
        tempCart[tempProductIndex].quantity+=1;
        setCartArray(tempCart);
        localStorage.setItem("cart",JSON.stringify(tempCart));
        }
        else if(action=="Remove")
        {
          if(tempCart[tempProductIndex].quantity==1)
          {
            tempCart.splice(tempProductIndex,1);
            if(tempCart.length==0)
            {
              localStorage.setItem("cart","");
             setCartArray([]);
            }
           else
           {
            setCartArray(tempCart);
           }
          }
          else
          {
            tempCart[tempProductIndex].quantity-=1;
            setCartArray(tempCart);
            localStorage.setItem("cart",JSON.stringify(tempCart));
       
          }
        }
      }
      else
      {
        tempCart.push({name:name,quantity:1,cost:cost,pictureUrl:pictureUrl});
        setCartArray(tempCart);
        localStorage.setItem("cart",JSON.stringify(tempCart));
      }
    }
   
      }
     
  
    }
const stripePromise = loadStripe('pk_test_51QJ787KKFlLtbIKzHbHMhEwiMbVbomto7zIZAVAPos9idWSSX03Cp7kNQayTsAGln4R0JO9VvaPjHcRkKE01uuGP00mTRRucKj');
const Stripe = require('stripe');
const stripe = Stripe('sk_test_51QJ787KKFlLtbIKzFYbqtpCfNh3AFQYHtfsQyjY4wCzz36wT4mOaedvtX5Yz5tJVpONTgs72Ko35fpov8ptDVLy60057FWw6mu');
const[isLoading,setIsLoading]=useState(false);
const[clientSecret,setClientSecret]=useState("");
const[paymentId,setPaymentId]=useState("");
  async function onCheckoutClicked()
{
  setIsLoading(true);
  const paymentIntent = await stripe.paymentIntents.create({
    amount: returnTheAmount()*100,
    currency: 'hkd',
    automatic_payment_methods: {
      enabled: true,
    },
  }).then((response)=>
  {

  setClientSecret(response.client_secret);
  setPaymentId(response.id);
  setIsLoading(false);
//   confirmPayment(response.id);
  });
}
function onCancelThePayment()
{
  setClientSecret("");
}
const returnTheAmount=()=>
{
  let total=0;

    for(let i=0;i<cartArray.length;i++)
    {
      total+=cartArray[i].cost*cartArray[i].quantity;
    }
  
  return total;
}
  return (
    <div className="w-screen h-screen flex flex-col absolute items-end z-[500]">
       <div className="w-full h-full flex flex-col relative items-end">
      <div className="w-screen h-screen bg-black opacity-75 absolute" />
      <div className="2xl:w-[30%] xl:w-[30%] lg:w-[30%] w-[90%] flex flex-col h-full bg-white border-2 border-black absolute">
        <div className="w-[95%] flex flex-row justify-around self-center items-center py-[10px]">
          <img
            src={CloseIcon}
            className="w-[20px] h-[20px] object-contain opacity-0 cursor-pointer"
          />
          <div className="text-xl text-black font-semibold">My Bag ({cartArray.length})</div>
          <img
            src={CloseIcon}
            className="w-[20px] h-[20px] object-contain cursor-pointer"
            onClick={() => {
              props.closeButtonAction();
            }}
          />
        </div>
        <div className="w-full flex flex-col bg-gray-200 p-[5px]">
          <div className="flex flex-row w-full h-full items-center gap-x-[10px]">
            <img
              src={DeliveryIcon}
              className="w-[40px] h-[40px] object-contain"
            />
            <div className="text-[14px]">
              You now qualify for&nbsp;
              <span className="font-bold text-black ">Free Shipping!</span>
            </div>
          </div>
        </div>
        <div className="h-[70%] overflow-y-auto flex flex-col">
          {
          cartArray.map((item, index) => (
            <CartCard
              props={{
                productName: item.name,
                productPrice: item.cost,
                quantity:item.quantity,
                isNew: item.isNew,
                starRating: item.starRating,
                thumbnailUrl: item.pictureUrl,
                isRecommended: false,
                addItem:addToCartClicked,
                removeItem:addToCartClicked
              }}
              key={index}
            />
          ))}
        </div>
        <div className="w-full border-t-[1px] border-t-gray-300" />
        <div className="w-[95%] self-center flex flex-row font-bold text-base text-black justify-between mt-[5px]">
          <div>Total</div>
          <div>HKD.{returnTheAmount()}</div>
        </div>
        <button disabled={isLoading} className="w-[98%] bg-black py-[5px] rounded-sm text-white font-bold self-center" onClick={()=>onCheckoutClicked()}>PROCEED TO CHECKOUT</button>
       {
        clientSecret.length>0?
        <div style={{position:"absolute",width:"100%",height:"100%",backgroundColor:"white"}}>
        <Elements stripe={stripePromise} options={{clientSecret:clientSecret}}>
<PaymentElementStripte props={{cancelPaymentFunction:onCancelThePayment()}}/>
        </Elements>
        </div>:null
       }
        
      </div>
      </div>

      
    </div>
  );

}
export default Cart;
