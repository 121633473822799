
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Categories from "../Pages/Categories";
import Products from "../Pages/Products";
import AboutProduct from "../Pages/AboutProduct";
import SearchPage from "../Pages/SearchPage";
import LoginPage from "../Pages/LoginPage";
function RoutingComponent() {
  return (
    <div className="w-full">
      <Router>
        <Routes>
          <Route path="/" element={<Categories />} />
          <Route path="/products/:categoryId" element={<Products />} />
          <Route path="/aboutProduct" element={<AboutProduct />} />
          <Route path="/searchPage" element={<SearchPage />} />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </Router>
      
    </div>
  );
}

export default RoutingComponent;
