import React from "react";
import { CardElement, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
function PaymentElementStripe({props})
{
    const stripe = useStripe();
    const elements = useElements();
    async function confirmPayment()
{
  const paymentIntent = await stripe.paymentIntents.confirm({
   elements,
    confirmParams:{
     
      return_url: 'https://www.example.com',
    }
}).then((response)=>
  {
    localStorage.setItem("cart","");
    console.log("Response");
    alert('Successfully Placed The Order');
  })
  .catch((error)=>
  {
    console.log(error);
  });

}

    return(
        <div className="flex flex-col justify-center items-center gap-y-[10px] w-full h-full">
<PaymentElement/>
<div className="flex flex-row w-[32%] justify-around">
<button className="bg-[#D3D3D3] text-black py-[2px] rounded-md px-[10px] font-bold" onClick={()=>confirmPayment()}>CONFIRM</button>
<button className="bg-[#FF0000] text-white py-[2px] rounded-md px-[10px] font-bold" onClick={()=>props.cancelPaymentFunction()}>CANCEL</button>
</div>
</div>
    );
}
export default PaymentElementStripe;