import React from "react";
import HamburgerIcon from "../Assets/Hamburger.png";
import SearchIcon from "../Assets/Search.png";
import MainLogoIcon from "../Assets/MainLogo.png";
import ProfileIcon from "../Assets/Profile.png";
import CartIcon from "../Assets/Cart.png";
import { useNavigate } from "react-router-dom";
function Header({ props }) {
  const navigate = useNavigate();

  return (
    <div className="rowLayout justify-between items-center bg-[#FFFFFF] p-[10px] w-full">
      <div className="rowLayout gap-x-[10px]">
        <img className="squareIcons cursor-pointer" src={HamburgerIcon} />
        <img
          className="squareIcons cursor-pointer"
          onClick={() => navigate("/searchPage")}
          src={SearchIcon}
        />
      </div>
      <img src={MainLogoIcon} className="w-[300px] h-[60px] object-contain" />
      <div className="rowLayout gap-x-[10px]">
        <img
          className="squareIcons cursor-pointer"
          onClick={() => navigate("/login")}
          src={ProfileIcon}
        />
        <img
          className="squareIcons cursor-pointer"
          src={CartIcon}
          onClick={() => {
            props.onPressCart();
          }}
        />
      </div>
    </div>
  );
}
export default Header;
