import {createSlice} from '@reduxjs/toolkit';
export const PolicySlice = createSlice({
  name: 'policy',
  initialState: {
    value: [],
  },
  reducers: {
    populatePolicy: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populatePolicy} = PolicySlice.actions;
export default PolicySlice.reducer;
