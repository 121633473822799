import React from "react";
import Star from "../Assets/Star.png";
import StarFill from "../Assets/StarFill.png";
import AddToBagIcon from "../Assets/AddToBagIcon.png";
function ProductCard({ props }) {
  return (
    <div
      onClick={() => props.onClickTheProduct()}
      className="flex flex-col 2xl:h-[400px] xl:h-[400px] lg:h-[400px] max-h-[300px] items-center justify-center flex-none cursor-pointer"
    >
      <div className="h-full gap-y-[10px]  flex flex-col   border-transparent w-[90%] justify-between relative">
        <div
          className="2xl:h-[300px] xl:h-[300px] lg:h-[300px] h-[200px]"
          style={{
            backgroundImage: `url(${props.thumbnailUrl})`,
            width: "100%",

            backgroundSize: "100%,100%",
            backgroundRepeat: "round",
            position: "relative",
          }}
        >
          {props.isNew == true ? (
            <div className="bg-[#F5F5F5] border-2 border-transparent px-[3px] py-[1px] rounded-md text-black top-[5px] left-[5px] absolute font-semibold">
              New
            </div>
          ) : null}
          <div className="w-[25px] h-[25px] rounded-full bg-blue-100 items-center justify-center absolute bottom-[5px] right-[5px]  cursor-pointer flex flex-col">
            <img src={AddToBagIcon} className=" h-[20px] w-[20px] object-fit" />
          </div>
        </div>

        <div className="flex flex-row w-full gap-x-[5px] px-[5px]">
          <div className="w-[20px] h-[20px] border-2 border-transparent rounded-full bg-red-500" />
          <div className="w-[20px] h-[20px] border-2 border-transparent rounded-full bg-yellow-500" />
          <div className="w-[20px] h-[20px] border-2 border-transparent rounded-full bg-blue-500" />
          <div className="w-[20px] h-[20px] border-2 border-transparent rounded-full bg-orange-500" />
        </div>
        <div className="text-black px-[5px] font-semibold text-sm">
          {props.productName.length > 45
            ? props.productName.substring(0, 45) + "..."
            : props.productName}
          {/* SpongeBob SquarePants Family matching Chr.. */}
        </div>
        <div className="flex flex-row w-full gap-x-[5px] px-[5px] items-center">
          <img
            src={props.starRating >= 1 ? StarFill : Star}
            className="w-[20px] h-[20px] object-contain"
          />
          <img
            src={props.starRating >= 2 ? StarFill : Star}
            className="w-[20px] h-[20px] object-contain"
          />
          <img
            src={props.starRating >= 3 ? StarFill : Star}
            className="w-[20px] h-[20px] object-contain"
          />
          <img
            src={props.starRating >= 4 ? StarFill : Star}
            className="w-[20px] h-[20px] object-contain"
          />
          <img
            src={props.starRating == 5 ? StarFill : Star}
            className="w-[20px] h-[20px] object-contain"
          />
        </div>
        <div className="text-gray-900 px-[5px] font-medium text-sm">
          From{" "}
          <span className="font-bold text-black">
            {/* HKD.123 */}
            HKD.{props.productPrice}
          </span>
        </div>
      </div>
    </div>
  );
}
export default ProductCard;
