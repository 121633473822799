import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  value: {
    totalCategories: 0,
    totalProducts: 0,
    totalBanners: 0,
    totalPromotionalText: 0,
    totalPolicies: 0,
    totalCustomers: 0,
    totalOrders: 0,
  },
};

export const MetadataSlice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    populateMetadata: (state, action) => {
      switch (action.payload.type) {
        case 'totalCategories':
          state.value.totalCategories = action.payload.sol;
          break;
        case 'totalProducts':
          state.value.totalProducts = action.payload.sol;
          break;
        case 'totalBanners':
          state.value.totalBanners = action.payload.sol;
          break;
          case 'totalPromotionalText':
            state.value.totalPromotionalText = action.payload.sol;
            break;
          case 'totalPolicies':
            state.value.totalPolicies = action.payload.sol;
            break;
          case 'totalCustomers':
            state.value.totalCustomers = action.payload.sol;
            break;
            case 'totalOrders':
              state.value.totalOrders = action.payload.sol;
              break;
            
      }
    },
  },
});
export const {populateMetadata} = MetadataSlice.actions;

export default MetadataSlice.reducer;
