import Pic1 from "../Assets/Pic1.jpg";
import Pic2 from "../Assets/Pic2.jpg";
import Pic3 from "../Assets/Pic3.jpg";
import Pic4 from "../Assets/Pic4.jpg";
import Pic5 from "../Assets/Pic5.jpg";
import Pic6 from "../Assets/Pic6.jpg";
import Pic7 from "../Assets/Pic7.jpg";
export const CategoryData = [
  {
    id: 1,
    name: "Baby Girls",
    ageGroup: "0-3y",
  },
  {
    id: 2,
    name: "Baby Boys",
    ageGroup: "0-3y",
  },
  {
    id: 3,
    name: "Toddler Girls",
    ageGroup: "2-6y",
  },
  {
    id: 4,
    name: "Toddler Boys",
    ageGroup: "2-6y",
  },
  {
    id: 5,
    name: "Girls",
    ageGroup: "4-12y",
  },
  {
    id: 6,
    name: "Boys",
    ageGroup: "4-12y",
  },
  {
    id: 7,
    name: "Matching Outfits",
    ageGroup: "",
  },
  {
    id: 8,
    name: "Characters",
    ageGroup: "",
  },
  {
    id: 1,
    name: "Baby Girls",
    ageGroup: "0-3y",
  },
  {
    id: 2,
    name: "Baby Boys",
    ageGroup: "0-3y",
  },
  {
    id: 3,
    name: "Toddler Girls",
    ageGroup: "2-6y",
  },
  {
    id: 4,
    name: "Toddler Boys",
    ageGroup: "2-6y",
  },
  {
    id: 5,
    name: "Girls",
    ageGroup: "4-12y",
  },
  {
    id: 6,
    name: "Boys",
    ageGroup: "4-12y",
  },
  {
    id: 7,
    name: "Matching Outfits",
    ageGroup: "",
  },
  {
    id: 8,
    name: "Characters",
    ageGroup: "",
  },
  {
    id: 1,
    name: "Baby Girls",
    ageGroup: "0-3y",
  },
  {
    id: 2,
    name: "Baby Boys",
    ageGroup: "0-3y",
  },
  {
    id: 3,
    name: "Toddler Girls",
    ageGroup: "2-6y",
  },
  {
    id: 4,
    name: "Toddler Boys",
    ageGroup: "2-6y",
  },
  {
    id: 5,
    name: "Girls",
    ageGroup: "4-12y",
  },
  {
    id: 6,
    name: "Boys",
    ageGroup: "4-12y",
  },
  {
    id: 7,
    name: "Matching Outfits",
    ageGroup: "",
  },
  {
    id: 8,
    name: "Characters",
    ageGroup: "",
  },
  {
    id: 1,
    name: "Baby Girls",
    ageGroup: "0-3y",
  },
  {
    id: 2,
    name: "Baby Boys",
    ageGroup: "0-3y",
  },
  {
    id: 3,
    name: "Toddler Girls",
    ageGroup: "2-6y",
  },
  {
    id: 4,
    name: "Toddler Boys",
    ageGroup: "2-6y",
  },
  {
    id: 5,
    name: "Girls",
    ageGroup: "4-12y",
  },
  {
    id: 6,
    name: "Boys",
    ageGroup: "4-12y",
  },
  {
    id: 7,
    name: "Matching Outfits",
    ageGroup: "",
  },
  {
    id: 8,
    name: "Characters",
    ageGroup: "",
  },
];
export const ProductData = [
  {
    id: 1,
    productName:
      "Family Matching Sets Green Long Sleeves Shirt or Ribbed Off-Shoulder Embroidered Tulle Dress",
    productPrice: 2012,
    starRating: 4,
    isNew: true,
    thumbnailUrl: Pic1,
  },
  {
    id: 2,
    productName:
      "Family Matching Color-Block Knit Long-Sleeved Dresses And Tops Sets",
    productPrice: 1174,
    starRating: 3,
    isNew: false,
    thumbnailUrl: Pic2,
  },
  {
    id: 3,
    productName:
      "Family Christmas Pajamas Red Traditional Christmas Treeprint PJS",
    productPrice: 1509,
    starRating: 5,
    isNew: false,
    thumbnailUrl: Pic3,
  },
  {
    id: 4,
    productName:
      "Matching Christmas Pajamas Tree & Car Print White Long-sleeve Pajamas Sets",
    productPrice: 1425,
    starRating: 3,
    isNew: true,
    thumbnailUrl: Pic4,
  },
  {
    id: 5,
    productName: "Mommy and Me Letter Print Green Long-sleeve Hoodie Dresses",
    productPrice: 1341,
    starRating: 3,
    isNew: false,
    thumbnailUrl: Pic5,
  },
  {
    id: 6,
    productName:
      "Family Matching Sets Caramel Color Tee or Ribbed Body-con Dress or Embroidered Tulle Dress",
    productPrice: 838,
    starRating: 5,
    isNew: true,
    thumbnailUrl: Pic6,
  },
  {
    id: 7,
    productName:
      "Family Christmas Pajamas Gingerbread Cartoon Print Pajamas Sets",
    productPrice: 754,
    starRating: 4,
    isNew: false,
    thumbnailUrl: Pic7,
  },
];
export const shippingPolicy = {
  title: "Free Shipping",
  description: "PatPat provides Free Standard Shipping on orders over ₹2,097",
};
export const returnPolicy = {
  title: "Easy Return",
  description:
    "Our guarantee: Return or exchange within 30 days from delivery date.",
};
