import React from "react";
function ReviewCard({ props }) {
  return (
    <div className="flex flex-col flex-none   rounded-md p-[5px] bg-blue-200">
      <div className="flex flex-row items-center justify-between ">
        <div className="font-bold">{props.customerName}</div>
        <div className="font-medium text-base">{props.reviewTime}</div>
      </div>
      <div className="font-medium">{props.customerReview}</div>
    </div>
  );
}
export default ReviewCard;
