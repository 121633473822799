import {createSlice} from '@reduxjs/toolkit';
export const OrdersSlice = createSlice({
  name: 'orders',
  initialState: {
    value: [],
  },
  reducers: {
    populate_orders: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populate_orders} = OrdersSlice.actions;
export default OrdersSlice.reducer;
