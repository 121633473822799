import React, { useEffect, useState } from "react";
import { ProductData } from "../TemporaryData/TemporaryData";
import ProductCard from "../Cards/ProductCard";
import Pic1 from "../Assets/Pic1.jpg";
import RecommendedCard from "../Cards/RecommendedCard";
import SearchIcon from "../Assets/Search.png";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
function SearchPage() {
  useEffect(()=>
  {
    window.addEventListener("unload",(e)=>
      {
        e.preventDefault();
      })
  },[]);
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: "100vw",
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="flex flex-col w-[95%] self-center gap-y-[20px] py-[20px]">
        <div className="flex flex-row w-full justify-between">
          <div className="flex flex-row bg-gray-100 p-[5px] justify-around items-center w-[80%]">
            <img
              src={SearchIcon}
              className="w-[25px] h-[25px] object-contain"
            />
            <input
              type="text"
              placeholder="Search for products"
              className="w-[80%] outline-none text-black text-base bg-transparent h-[35px]"
            />
          </div>
          <button
            onClick={() => navigate("/")}
            className="bg-transparent text-black text-base p-[5px] rounded-lg"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
export default SearchPage;
