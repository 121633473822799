import React, { useEffect, useState } from "react";
import { ProductData } from "../TemporaryData/TemporaryData";
import ProductCard from "../Cards/ProductCard";
import Pic1 from "../Assets/Pic1.jpg";
import RecommendedCard from "../Cards/RecommendedCard";
import SearchIcon from "../Assets/Search.png";
import { useNavigate } from "react-router-dom";
import SaleBanner from "../Assets/SaleBanner.jpg";
import Header from "../Components/Header";
import MainLogoIcon from "../Assets/MainLogo.png";
import CloseIcon from "../Assets/CloseIcon.png";
import { collection, query, where,getDocs,addDoc,Timestamp } from "firebase/firestore";
import {createUserWithEmailAndPassword,signInWithEmailAndPassword,onAuthStateChanged,signOut } from "firebase/auth";
import {useDispatch} from 'react-redux';
import { populateCustomer } from "../Redux/CustomerSlice.js";
import {db,auth} from '../Components/FirebaseInit.js';
function LoginPage() {
  const[isLoggedIn,setIsLoggedIn]=useState(false);
  useEffect(()=>
  {
    onAuthStateChanged(auth, (user) => {
      if (user) {
      setIsLoggedIn(true);
      setCustomerName(localStorage.getItem("customerName"));
      setCustomerMobile(localStorage.getItem("customerMobileNumber"));
      setCustomerAddress(localStorage.getItem(JSON.parse(localStorage.getItem("customerAddress"))["address"]));
      setCustomerCity(JSON.parse(localStorage.getItem("customerAddress"))["city"]);
      setCustomerState(JSON.parse(localStorage.getItem("customerAddress"))["state"]);
      setCustomerCountry(JSON.parse(localStorage.getItem("customerAddress"))["country"]);
      setCustomerEmail(localStorage.getItem("customerEmail"));
      setCustomerPassword(localStorage.getItem("customerPassword"));
      setCustomerZipcode(JSON.parse(localStorage.getItem("customerAddress"))["zipcode"]);
      console.log("Logged In");
      
      } else {
      setIsLoggedIn(false);

      }
    });
  },[]);
  const dispatch=useDispatch();
  const[customerEmail,setCustomerEmail]=useState("");
  const[customerPassword,setCustomerPassword]=useState("");
  const[customerName,setCustomerName]=useState("");
  const[customerMobile,setCustomerMobile]=useState("");
  const[customerAddress,setCustomerAddress]=useState("");
  const[customerCity,setCustomerCity]=useState("");
  const[customerState,setCustomerState]=useState("");
  const[customerCountry,setCustomerCountry]=useState("");
  const[customerZipcode,setCustomerZipcode]=useState("");
  const[authenticationMode,setAuthenticationMode]=useState("Signin");
  const onSignoutClicked=()=>
  {
    signOut(auth).then(() => {
    localStorage.setItem("customerAddress","");
    localStorage.setItem("customerName","");
    localStorage.setItem("customerMobileNumber","");
    localStorage.setItem("customerEmail","");
    localStorage.setItem("cart","");
    dispatch(populateCustomer({type:"customerAddress",sol:""}));
    dispatch(populateCustomer({type:"customerName",sol:""}));
    dispatch(populateCustomer({type:"customerEmail",sol:""}));
    dispatch(populateCustomer({type:"customerMobileNumber",sol:""}))
    setCustomerName("");
    setCustomerAddress("");
    setCustomerCity("");
    setCustomerState("");
    setCustomerCountry("");
    setCustomerZipcode("");
    setCustomerEmail("");
    setCustomerPassword("");
    navigate("/");
    }).catch((error) => {
      // An error happened.
    });
  }
  const checkTheCustomerInformation=async()=>
  {
    const customersRef = collection(db, "customers");
    const q = query(customersRef, where("customerEmail", "==",customerEmail ));
    const querySnapshot = await getDocs(q);

    if(querySnapshot.docs.length>0)
    {
      console.log(querySnapshot.docs[0].data());
      signInWithEmailAndPassword(auth, customerEmail, customerPassword)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        localStorage.setItem("customerAddress",querySnapshot.docs[0].data().customerAddress);
        localStorage.setItem("customerName",querySnapshot.docs[0].data().customerName);
        localStorage.setItem("customerEmail",querySnapshot.docs[0].data().customerEmail);
        localStorage.setItem("customerPassword",querySnapshot.docs[0].data().customerPassword);
        localStorage.setItem("customerMobileNumber",querySnapshot.docs[0].data().customerMobileNumber);
        dispatch(populateCustomer({type:"customerAddress",sol:querySnapshot.docs[0].data().customerAddress}));
        dispatch(populateCustomer({type:"customerName",sol:querySnapshot.docs[0].data().customerName}));
        dispatch(populateCustomer({type:"customerEmail",sol:querySnapshot.docs[0].data().customerEmail}));
        dispatch(populateCustomer({type:"customerMobileNumber",sol:querySnapshot.docs[0].data().customerMobileNumber}))
        setCustomerAddress(JSON.parse(querySnapshot.docs[0].data().customerAddress)["address"]);
        setCustomerCity(JSON.parse(querySnapshot.docs[0].data().customerAddress)["city"]);
        setCustomerState(JSON.parse(querySnapshot.docs[0].data().customerAddress)["state"]);
        setCustomerCountry(JSON.parse(querySnapshot.docs[0].data().customerAddress)["country"]);
        setCustomerZipcode(JSON.parse(querySnapshot.docs[0].data().customerAddress)["zipcode"]);
        setCustomerName(querySnapshot.docs[0].data().customerName);
        setCustomerMobile(querySnapshot.docs[0].data().customerMobileNumber);
        setCustomerEmail(querySnapshot.docs[0].data().customerEmail);
        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
    }
    else if(querySnapshot.docs.length==0)
    {
      setAuthenticationMode("Registration");
    }
  }
  const onClickContinue=async()=>
  {
    if(authenticationMode=="Signin")
    {
    checkTheCustomerInformation();
    }
    else if(authenticationMode=="Registration")
    {
      createUserWithEmailAndPassword(auth, customerEmail, customerPassword)
  .then((userCredential) => {
    addTheCustomerInFirestore();
    
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;

  });
      
    }
  }
  const addTheCustomerInFirestore=async()=>
  {
    const docRef = await addDoc(collection(db, "customers"), {
      customerEmail:customerEmail,
      customerName:customerName,
      customerMobileNumber:customerMobile,
      customerAddress:JSON.stringify({address:customerAddress,city:customerCity,state:customerState,zipcode:customerZipcode,country:customerCountry}),
      createdAt:Timestamp.fromDate(new Date())
    });
    console.log("Document written with ID: ", docRef.id);
    localStorage.setItem("customerAddress",JSON.stringify({address:customerAddress,city:customerCity,state:customerState,zipcode:customerZipcode,country:customerCountry}));
    localStorage.setItem("customerName",customerName);
    localStorage.setItem("customerEmail",customerEmail);
    localStorage.setItem("customerMobileNumber",customerMobile);
    dispatch(populateCustomer({type:"customerAddress",sol:JSON.stringify({address:customerAddress,city:customerCity,state:customerState,zipcode:customerZipcode,country:customerCountry})}));
    dispatch(populateCustomer({type:"customerName",sol:customerName}));
    dispatch(populateCustomer({type:"customerEmail",sol:customerEmail}));
    dispatch(populateCustomer({type:"customerMobileNumber",sol:customerMobile}))
    
    navigate("/");
  }
  const navigate = useNavigate();
  useEffect(()=>
  {
    window.addEventListener("unload",(e)=>
      {
        e.preventDefault();
      })
  },[]);
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="flex flex-row w-[100%] h-full self-center">
        <div className="2xl:w-[50%] xl:w-[50%] lg:w-[50%] w-0 flex flex-col h-full justify-center items-center">
          <img src={SaleBanner} className="w-full h-full object-fill" />
        </div>
        <div className="2xl:w-[50%] xl:w-[50%] lg:w-[50%] w-full flex flex-col h-full justify-around items-center">
          <div className="flex flex-row 2xl:w-0 xl:w-0 lg:w-0 w-[90%] self-center  justify-between items-center">
            <img
              src={CloseIcon}
              className="w-[25px] h-[25px] object-contain opacity-0"
            />
            <img
              src={MainLogoIcon}
              className="w-[200px] h-[40px] object-contain"
            />
            <img
              src={CloseIcon}
              className="w-[25px] h-[25px] object-contain cursor-pointer"
              onClick={() => navigate("/")}
            />
          </div>
          <div className="w-full  2xl:gap-y-[20px] xl:gap-y-[10px] lg:gap-y-[20px] gap-y-[20px] h-full overflow-y-auto flex-start  items-center  flex flex-col ">
            <div className="flex-none text-2xl font-semibold text-[#36454F] text-center">
              Sign in / Register
            </div>
            <input
            disabled={isLoggedIn}
              type="text"
              placeholder="Email"
              value={customerEmail}
              onChange={(e)=>setCustomerEmail(e.target.value)}
              className="flex-none 2xl:w-[50%] xl:w-[40%] lg:w-[40%] w-[90%]  2xl:py-[10px] xl:py-[10px] lg:py-[10px] py-[7px] border-2 border-[#36454F] text-lg outline-none px-[10px]"
            />
            <input
                          disabled={isLoggedIn}
              maxLength={8}
              type="password"
              placeholder="Password"
              value={customerPassword}
              onChange={(e)=>setCustomerPassword(e.target.value)}
              className="flex-none 2xl:w-[50%] xl:w-[40%] lg:w-[40%] w-[90%]  2xl:py-[10px] xl:py-[10px] lg:py-[10px] py-[7px] border-2 border-[#36454F] text-lg outline-none px-[10px]"
            />
            {authenticationMode=="Registration"||isLoggedIn==true?<>
            <input
              disabled={isLoggedIn}
              type="text"
              value={customerName}
              onChange={(e)=>setCustomerName(e.target.value)}
              placeholder="Full Name"
              className="flex-none 2xl:w-[50%] xl:w-[40%] lg:w-[40%] w-[90%]  2xl:py-[10px] xl:py-[10px] lg:py-[10px] py-[7px] border-2 border-[#36454F] text-lg outline-none px-[10px]"
            />
             <input
                           disabled={isLoggedIn}
              type="number"
              value={customerMobile}
              onChange={(e)=>setCustomerMobile(e.target.value)}
              maxLength={15}
              placeholder="Contact Number(with country code)"
              className="flex-none 2xl:w-[50%] xl:w-[40%] lg:w-[40%] w-[90%]  2xl:py-[10px] xl:py-[10px] lg:py-[10px] py-[7px] border-2 border-[#36454F] text-lg outline-none px-[10px]"
            />
             <input
                           disabled={isLoggedIn}
              type="text"
              placeholder="Address"
              value={customerAddress}
              onChange={(e)=>setCustomerAddress(e.target.value)}
       
              className="flex-none 2xl:w-[50%] xl:w-[40%] lg:w-[40%] w-[90%]  2xl:py-[10px] xl:py-[10px] lg:py-[10px] py-[7px] border-2 border-[#36454F] text-lg outline-none px-[10px]"
            />
            <input
                          disabled={isLoggedIn}
              type="text"
              placeholder="City"
              value={customerCity}
              onChange={(e)=>setCustomerCity(e.target.value)}
              
              className="flex-none 2xl:w-[50%] xl:w-[40%] lg:w-[40%] w-[90%]  2xl:py-[10px] xl:py-[10px] lg:py-[10px] py-[7px] border-2 border-[#36454F] text-lg outline-none px-[10px]"
            />
            <input
                          disabled={isLoggedIn}
              type="text"
              placeholder="State"
              value={customerState}
              onChange={(e)=>setCustomerState(e.target.value)}
              
              className="flex-none 2xl:w-[50%] xl:w-[40%] lg:w-[40%] w-[90%]  2xl:py-[10px] xl:py-[10px] lg:py-[10px] py-[7px] border-2 border-[#36454F] text-lg outline-none px-[10px]"
            />
            <input
                          disabled={isLoggedIn}
              type="text"
              value={customerCountry}
              onChange={(e)=>setCustomerCountry(e.target.value)}
        
              placeholder="Country"
              className="flex-none 2xl:w-[50%] xl:w-[40%] lg:w-[40%] w-[90%]  2xl:py-[10px] xl:py-[10px] lg:py-[10px] py-[7px] border-2 border-[#36454F] text-lg outline-none px-[10px]"
            />
            <input
                          disabled={isLoggedIn}
              type="text"
              value={customerZipcode}
              onChange={(e)=>setCustomerZipcode(e.target.value)}
              maxLength={6}
              placeholder="Zipcode"
              className="flex-none 2xl:w-[50%] xl:w-[40%] lg:w-[40%] w-[90%]  2xl:py-[10px] xl:py-[10px] lg:py-[10px] py-[7px] border-2 border-[#36454F] text-lg outline-none px-[10px]"
            />
            </>:null}
            {isLoggedIn==false?<button 
            disabled={authenticationMode=="Signin"?customerEmail.length==0||customerPassword.length==0:
              authenticationMode=="Registration"?customerEmail.length==0||customerPassword.length==0||customerName.length==0||customerAddress.length==0||customerCity.length==0||customerState.length==0||customerCountry.length==0||customerZipcode.length==0:null}
            
            onClick={()=>onClickContinue()} className="2xl:w-[40%] xl:w-[40%] lg:w-[40%] w-[90%]  2xl:py-[10px] xl:py-[10px] lg:py-[10px] py-[7px]  bg-black text-2xl text-white outline-none px-[10px] font-bold">
              CONTINUE
            </button>:
            <button onClick={()=>onSignoutClicked()} className="2xl:w-[40%] xl:w-[40%] lg:w-[40%] w-[90%]  2xl:py-[10px] xl:py-[10px] lg:py-[10px] py-[7px]  bg-black text-2xl text-white outline-none px-[10px] font-bold">
              SIGNOUT
            </button>}
            <div className="text-[#36454F] text-center text-base">
              Your privacy will be well protected,
              <br />
              please refer our{" "}
              <span className="text-[#0000EE] cursor-pointer">
                Privacy Policy
              </span>
              .
            </div>
          </div>
          <div className="flex flex-row 2xl:w-0 xl:w-0 lg:w-0 w-[90%] self-center justify-between items-center opacity-0">
            <img
              src={MainLogoIcon}
              className="w-[200px] h-[40px] object-contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoginPage;
