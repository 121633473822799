import React, { useEffect, useRef } from "react";
import B1 from "../Assets/B1.jpg";
import B2 from "../Assets/B2.jpg";
import B3 from "../Assets/B3.jpg";
import B4 from "../Assets/B4.jpg";
import B5 from "../Assets/B5.jpg";
function BannerSlider() {
  const bannerRef = useRef();
  useEffect(() => {
    console.log(
      "Current Scroll Position Of Banner:" + bannerRef.current.position
    );
    let inter = setInterval(() => {
      console.log("Check");
      //  let currentScrollPosition = bannerRef.current.scrollX;
      bannerRef.current.scrollRight = window.innerWidth;
    }, 2000);
    return () => clearInterval(inter);
  }, []);
  return (
    <div
      ref={bannerRef}
      className="flex flex-row h-[300px] w-[100%] border-2 border-black self-center 2xl:bg-white xl:bg-white lg:bg-white bg-yellow-400 overflow-x-auto"
    >
      <img src={B1} className="w-full h-[275px] flex-none object-fill" />
      <img src={B2} className="w-full h-[275px] flex-none object-fill" />
      <img src={B3} className="w-full h-[275px] flex-none object-fill" />
      <img src={B4} className="w-full h-[275px] flex-none object-fill" />
      <img src={B5} className="w-full h-[275px] flex-none object-fill" />
    </div>
  );
}
export default BannerSlider;
