import {createSlice} from '@reduxjs/toolkit';
export const PromotionalTextSlice = createSlice({
  name: 'promotionalText',
  initialState: {
    value: [],
  },
  reducers: {
    populatePromotionalText: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const {populatePromotionalText} = PromotionalTextSlice.actions;
export default PromotionalTextSlice.reducer;
