import React, { useEffect, useState } from "react";
import { ProductData } from "../TemporaryData/TemporaryData";
import ProductCard from "../Cards/ProductCard";
import Pic1 from "../Assets/Pic1.jpg";
import RecommendedCard from "../Cards/RecommendedCard";
import { useNavigate,useParams } from "react-router-dom";
import Header from "../Components/Header";
import Cart from "../Components/Cart.js";
import WhatsappIcon from "../Assets/WhatappIcon.png";
import{useSelector,useDispatch}from 'react-redux';

function Products() {
  const products=useSelector((state)=>state.callProduct.value);
  const dispatch=useDispatch();
  const navigate = useNavigate();
  const [showCart, setShowCart] = useState(false);
  const{categoryId}=useParams();
  const[productsToDisplay,setProductsToDisplay]=useState([]);
  useEffect(()=>
  {
    window.addEventListener("unload",(e)=>
    {
      e.preventDefault();
    })
    let tempProductsToDisplay=products.filter(e=>e.associatedCategory==categoryId);
    setProductsToDisplay(tempProductsToDisplay);
  },[]);
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#F5F5F5",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header props={{ onPressCart: () => setShowCart(true) }} />
      <div className="flex flex-col w-[98%]  self-center">
        <div className="flex flex-col h-[87vh] overflow-y-auto gap-y-[20px]">
          <div className="flex flex-col ">
            <div className=" text-black  font-semibold text-lg">
              Recommended
            </div>
            <div className="flex flex-row w-full overflow-x-auto  gap-x-[20px] mt-[10px] ">
              {products.map((item, index) => (
                <RecommendedCard
                  key={index}
                  props={{
                    productImage: item.pictures.split('|')[0],
                    productName:
                      item.name.length > 40
                        ? item.name.substring(0, 41) + "..."
                        : item.name,
                  }}
                />
              ))}
            </div>
          </div>
          <div className="flex flex-row w-full 2xl:gap-x-[0px] xl:gap-x-[10px] lg:gap-x-[0px] gap-x-[10px] items-center 2xl:justify-start xl:justify-start lg:justify-start justify-around">
            {/* <div className=" text-black  font-semibold text-lg">Filters</div> */}
            <select className="p-[5px] text-base text-black bg-transparent text-center border-0  rounded-lg shadow-none border-gray-400 bg-orange-500">
              <option value="" selected>
                Sort
              </option>
              <option value="Ascending (A-Z)">Ascending</option>
              <option value="Descending (Z-A)">Descending</option>
            </select>
            <select className="p-[5px] text-base text-black bg-transparent text-center border-0  rounded-lg shadow-none border-gray-400">
              <option value="" selected>
                Size
              </option>
              <option value="3-6M">3-6 M</option>
              <option value="6-9M">6-9 M</option>
              <option value="9-12M">9-12 M</option>
              <option value="12-15M">12-15 M</option>
              <option value="15-18M">15-18 M</option>
            </select>
            <select className="p-[5px] text-base text-black bg-transparent text-center border-0  rounded-lg shadow-none border-gray-400">
              <option value="" selected>
                Color
              </option>
              <option value="Orange">Orange</option>
              <option value="Yellow">Yellow</option>
              <option value="Red">Red</option>
              <option value="Blue">Blue</option>
              <option value="Pink">Pink</option>
              <option value="Violet">Violet</option>
            </select>
          </div>
          <div className="flex flex-row w-full ">
            <div className="flex flex-col overflow-y-auto bg-white h-full w-full">
              <div className="w-full h-full grid 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-4 grid-cols-2  overflow-y-auto gap-y-[20px]">
                {productsToDisplay.map((item, index) => (
                  <ProductCard
                    key={index}
                    props={{
                      productName: item.name,
                      productPrice: item.cost,
                      isNew: true,
                      starRating: 5,
                      thumbnailUrl: item.pictures.split('|')[0],
                      isRecommended: false,
                      onClickTheProduct: () => navigate(`/aboutProduct`,{state:item}),
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showCart == true ? (
        <Cart props={{ closeButtonAction: () => setShowCart(false) }} />
      ) : null}
      <img
        src={WhatsappIcon}
        className="w-[50px] h-[50px] object-contain absolute bottom-[5px] right-[5px] cursor-pointer"
        onClick={()=>window.open(`https://wa.me/85251136004`)}
      />
    </div>
  );
}
export default Products;
