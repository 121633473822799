import {createSlice} from '@reduxjs/toolkit';
export const CustomerSlice = createSlice({
  name: 'customer',
  initialState: {
    value:{
      customerId:"",
      customerName:"",
      customerAddress:"",
      customerMobileNumber:"",
      createdAt:""
    },
  },
  reducers: {
    populateCustomer: (state, action) => {
      switch(action.payload.type)
      {
        case "customerId":
          state.customerId=action.payload.sol;
          break;
          case "customerName":
            state.customerName=action.payload.sol;
            break;
            case "customerAddress":
              state.customerAddress=action.payload.sol;
              break;
              case "customerMobileNumber":
                state.customerMobileNumber=action.payload.sol;
                break;
                case "createdAt":
                  state.createdAt=action.payload.sol;
                  break;
      }
      state.value = action.payload;
    },
  },
});
export const {populateCustomer} = CustomerSlice.actions;
export default CustomerSlice.reducer;
