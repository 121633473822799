import React from "react";
import PlusIcon from "../Assets/PlusIcon.png";
import MinusIcon from "../Assets/MinusIcon.png";
function CartCard({ props }) {
  return (
    <div className=" w-full flex flex-col px-[10px]">
      <div className="w-full flex flex-row py-[10px] gap-x-[10px]">
        <div className="w-[30%] h-[100px] flex flex-col">
          <img
            src={props.thumbnailUrl}
            className="w-full h-full object-contain"
          />
        </div>
        <div className="w-[65%] h-full  flex flex-col justify-around">
          <div className="text-base text-black">
            {props.productName.length > 40
              ? props.productName.substring(0, 40) + "..."
              : props.productName}
          </div>
          <div className="w-full flex flex-row justify-between">
            <div className="w-[50%] p-[2px] border-[1px] border-[#36454F] rounded-md justify-between items-center flex flex-row">
               <img
                onClick={()=>props.removeItem(props.productName,null,null,"Remove")}
                src={MinusIcon}
                className="w-[20px] h-[20px] object-contain"
              />
              {props.quantity}
              <img
              onClick={()=>props.addItem(props.productName,null,null,"Add")}
                src={PlusIcon}
                className="w-[20px] h-[20px] object-contain"
              />
            </div>
            <div className="text-base font-semibold text-black">
              HKD.{props.productPrice}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CartCard;
