import React, { useState, useEffect } from "react";
function CategoryCard({ props }) {
  return (
    <div
      onClick={() => props.onClickTheCategory()}
      style={{ width: props.cardWidth }}
      className={`h-[110px] rounded-lg shadow-md rowLayout justify-around items-center bg-[#FFFFFF] cursor-pointer`}
    >
      <div className="columnLayout gap-x-[5px] w-[60%]">
        <label className="fontSizes">{props.name}</label>
        <label className="fontSizes">{props.ageGroup}</label>
      </div>
      <img
        src={props.categoryImage}
        className="w-[50px] h-[50px] object-contain rounded-md"
      />
    </div>
  );
}
export default CategoryCard;
